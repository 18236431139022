import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

// Style
import style from './Sticky.module.scss'

const Sticky = ({ children }) => {
  const classes = cn(style.root, {})
  return <div className={classes}>{children}</div>
}

Sticky.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Sticky
