import React from 'react'

// Style
import style from './InputField.module.scss'

const InputField = ({
  id,
  invalid,
  invalidMsg,
  label,
  name,
  onChange,
  placeholder,
  required,
  type = 'text',
}) => (
  <div className={style.root}>
    {label && <label htmlFor={id ? id : name}>{label}</label>}

    <input
      type={type}
      name={name}
      className={`form-control ${style.input}`}
      id={id ? id : name}
      placeholder={placeholder}
      required={required}
      aria-required={required ? 'true' : undefined}
      onChange={onChange}
    />
    <div className="invalid-feedback">{invalidMsg}</div>
  </div>
)

export default InputField
