import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// Components
import { Row, Col } from 'reactstrap'
import Section from '@components/Section'
import { Box as ArticleBox } from '@components/Article'
import { Box as NewsletterBox } from '@components/Newsletter'
import Sticky from '@components/Sticky'
import SEO from '@components/SEO'

const commentsCounter = (comments, slug) => {
  let commentsLength = 0
  comments.forEach(comment => {
    if (decodeURIComponent(comment.path) === slug) {
      commentsLength++
    }
  })
  return commentsLength
}

const Homepage = ({
  data: { lastArticle, lastArticles, highlight },
  pageContext: { allComments, seoimage, seodescription },
}) => {
  const meta = []
  meta.push({
    property: `og:url`,
    content: `https://www.paroleallavoro.it/`,
  })
  meta.push({
    property: `author`,
    content: 'Anna Girardi',
  })
  if (seoimage) {
    meta.push({
      property: `og:image`,
      content: `https://www.paroleallavoro.it${seoimage}`,
    })
  }

  return (
    <>
      <SEO title="Home" description={seodescription} meta={meta} />
      {lastArticle.edges.map(({ node: { id, fields, frontmatter } }) => {
        return (
          <Section key={id} type="main" heading="Ultimo articolo">
            <Row>
              <Col md={{ size: 10, offset: 1 }}>
                <ArticleBox
                  main
                  title={frontmatter.title}
                  date={frontmatter.date}
                  commentsLength={commentsCounter(allComments, fields.slug)}
                  excerpt={fields.description}
                  slug={fields.slug}
                />
              </Col>
            </Row>
          </Section>
        )
      })}
      <Section type="highlight" heading="In evidenza">
        <Row>
          {highlight.edges.length ? (
            highlight.edges.map(
              ({ node: { id, fields, frontmatter } }, index) => {
                const classes = index > 0 ? 'mt-5 mt-md-0' : ''
                return (
                  <Col md="4" key={id} className={classes}>
                    <ArticleBox
                      title={frontmatter.title}
                      date={frontmatter.date}
                      commentsLength={commentsCounter(allComments, fields.slug)}
                      excerpt={fields.description}
                      slug={fields.slug}
                    />
                  </Col>
                )
              },
            )
          ) : (
            <Col>
              <p>Nessun articolo in evidenza.</p>
            </Col>
          )}
        </Row>
      </Section>
      <Section type="main" heading="Articoli recenti">
        <Row>
          <Col md="8">
            <Row>
              {lastArticles.edges.map(
                ({ node: { id, fields, frontmatter } }, index) => (
                  <Col md="6" className="mb-5" key={id}>
                    <ArticleBox
                      title={frontmatter.title}
                      date={frontmatter.date}
                      commentsLength={commentsCounter(allComments, fields.slug)}
                      excerpt={fields.description}
                      slug={fields.slug}
                    />
                  </Col>
                ),
              )}
            </Row>
          </Col>
          <Col
            md="4"
            style={{
              display: 'flex',
              alignItems: 'flex-start',
            }}
          >
            <Sticky>
              <NewsletterBox />
            </Sticky>
          </Col>
        </Row>
      </Section>
    </>
  )
}

Homepage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default Homepage

export const HomepageQuery = graphql`
  query IndexQuery {
    lastArticle: allMarkdownRemark(
      limit: 1
      filter: {
        frontmatter: {
          templateKey: { eq: "blog-post" }
          published: { eq: true }
        }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          ...BoxFrontmatter
        }
      }
    }
    highlight: allMarkdownRemark(
      limit: 3
      filter: {
        frontmatter: {
          templateKey: { eq: "blog-post" }
          published: { eq: true }
          highlight: { eq: true }
        }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          ...BoxFrontmatter
        }
      }
    }
    lastArticles: allMarkdownRemark(
      # limit: 8
      skip: 1
      filter: {
        frontmatter: {
          templateKey: { eq: "blog-post" }
          published: { eq: true }
          highlight: { ne: true }
        }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          ...BoxFrontmatter
        }
      }
    }
  }
`
