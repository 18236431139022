import React from 'react'
import cn from 'classnames'

import style from './Sidebar.module.scss'

const Sidebar = ({ children, type, className }) => {
  const classes = cn(style.root, {
    [className]: className,
    [`t-${type}`]: type,
  })
  return <aside className={classes}>{children}</aside>
}

export default Sidebar
