import React, { useState, useCallback } from 'react'
import cn from 'classnames'

// Utils
import { timer } from '@utils/async'
import { encode } from '@utils/encode'
import { upperFirst } from 'lodash'

// Style
import style from './Box.module.scss'

// Components
import Sidebar from '@components/Sidebar'
import InputField from '@components/InputField'
import Button from '@components/Button'

const STATES = {
  newsletter: 'newsletter',
  success: 'success',
  error: 'error',
}

const defaultState = {
  status: STATES.newsletter,
  form: {},
}

const Box = ({ children }) => {
  const [state, setState] = useState(defaultState)

  const submitHandler = useCallback(
    e => {
      e.preventDefault()
      const { name, email } = state.form
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': e.target.getAttribute('name'),
          name,
          email,
        }),
      })
        .then(() => {
          setState({
            ...state,
            status: STATES.success,
          })
          return timer(5000)
        })
        .then(() => {
          setState({
            ...state,
            status: STATES.newsletter,
          })
        })
    },
    [state],
  )

  const changeHandler = useCallback(
    e => {
      //
      setState({
        ...state,
        form: {
          ...state.form,
          [e.target.name]: e.target.value,
        },
      })
    },
    [state],
  )

  const classes = cn(style.root, {
    [style[`state${upperFirst(state.status)}`]]: state.status,
  })

  return (
    <Sidebar type="highlight" className={classes}>
      <div className={style.newsletter}>
        <h3 className="u-text--detail">Rimani aggiornato</h3>
        <small>
          Per ricevere gli articoli ancora caldi di inchiostro lascia qui:
        </small>
        <form
          action="#"
          method="POST"
          onSubmit={submitHandler}
          name="newsletter"
          action="/thanks"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <InputField
            name="name"
            invalidMsg="Inserisci il tuo nome"
            placeholder="Il tuo nome"
            onChange={changeHandler}
            required
          />
          <InputField
            name="email"
            type="email"
            invalidMsg="Inserisci un email valida"
            placeholder="La tua email"
            onChange={changeHandler}
            required
          />
          <div>
            <Button label="Iscriviti" type="submit" modifier="primary" block>
              Iscriviti
            </Button>
          </div>
        </form>
      </div>
      <div className={style.success}>
        <h4 className="u-text--primary">Grazie e a presto!</h4>
      </div>
      <div className={style.error}>
        <h4 className="u-text--primary">Ouch...</h4>
        <p className="u-text--intro">
          Qualcosa è andato storto. Per favore segnala il malfunzionamento a{' '}
          <a href="mailto:annagirardi@mail.com">annagirardi@mail.com</a>
        </p>
      </div>
    </Sidebar>
  )
}

Box.propTypes = {}

export default Box
